import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { Box, Container, IconButton, TextField } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import { useLazyGetImageSignedUrlQuery } from "../../api/Concernings";
import * as yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./WhistleBlowingView.css";
import { Button, Space, Switch, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button as MaterialButton } from "@mui/material";
import { LightTheme } from "../../Providers/ThemeProvider";
import { Whistleblowing } from "../../types/Whistleblowing";
import {
  useLazyCreateWhistleblowingQuery,
  useLazyGetWhistleblowingQuery,
  useLazyUpdateWhistleblowingQuery,
} from "../../api/Whistleblowing";

export default function WhistleBlowingEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [whistleblowing, setWhistleblowing] = useState<Whistleblowing>();
  const [file, setFile] = useState<UploadFile[]>([]);
  const [error, setError] = useState(false);
  const [isFileModified, setIsFileModified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getWhistleblowing] = useLazyGetWhistleblowingQuery();
  const [createWhistleblowing] = useLazyCreateWhistleblowingQuery();
  const [getContentImage] = useLazyGetImageSignedUrlQuery();
  const [updateWhistleblowing] = useLazyUpdateWhistleblowingQuery();
  const validationSchema = yup.object({
    title: yup.string().required("Titolo: Campo Obbligatorio"),
    text: yup.string().required("Descrizione: Campo Obbligatorio"),
    buttonLabel: yup
      .string()
      .required("Etichetta Pulsante: Campo Obbligatorio"),
    buttonLink: yup.string().required("Link Pulsante: Campo Obbligatorio"),
    active: yup.boolean(),
  });
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      ["link"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
  ];
  const props: UploadProps = {
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        if (onSuccess) onSuccess("ok");
      }, 1000);
    },
    beforeUpload: (file) => {
      const isImg = file.type.startsWith("image");
      if (!isImg) {
        enqueueSnackbar({
          variant: "error",
          message: "Puoi inserire solo file immagine",
          action: (key: SnackbarKey) => {
            return (
              <IconButton
                style={{ color: "white" }}
                onClick={() => closeSnackbar(key)}
              >
                <HighlightOffIcon />
              </IconButton>
            );
          },
        });
      }
      return isImg || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status !== "removed") {
        setFile([
          {
            originFileObj: info.file.originFileObj,
            uid: String(Date.now()),
            status: "done",
            name: info.file.name,
            url: URL.createObjectURL(info.file.originFileObj as File), // Crea un'anteprima
          },
        ]);
      } else {
        setFile([]);
      }
      setIsFileModified(true);
    },
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      title: undefined,
      text: undefined,
      buttonLabel: undefined,
      buttonLink: undefined,
      active: false,
    },
    onSubmit: ({ title, text, buttonLabel, buttonLink, active }) => {
      const formData = new FormData();
      if (title) formData.append("title", title);
      if (text) formData.append("text", text);
      if (buttonLabel) formData.append("buttonLabel", buttonLabel);
      if (buttonLink) formData.append("buttonLink", buttonLink);
      formData.append("active", active + "");
      if (file && file[0].originFileObj) {
        formData.append("imageContent", file[0].originFileObj as File);
      }
      if (id === "create") {
        createWhistleblowing(formData)
          .then(
            ({ data }) => {
              enqueueSnackbar({
                variant: "success",
                message: "Sezione Correttamente Creata",
                action: (key: SnackbarKey) => {
                  return (
                    <IconButton
                      style={{ color: "white" }}
                      onClick={() => closeSnackbar(key)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  );
                },
              });
              navigate(-1);
            },
            (err) => {
              enqueueSnackbar({
                variant: "error",
                message:
                  "Errore durante il salvataggio della sezione richiesta , riprova più tardi...",
              });
              setError(true);
            }
          )
          .catch((error) => {
            enqueueSnackbar({
              variant: "error",
              message:
                "Errore durante il salvataggio della sezione richiesta , riprova più tardi...",
            });
          });
        formik.isSubmitting = false;
      } else {
        updateWhistleblowing({ id: +id!, body: formData }).then(
          ({ data }) => {
            enqueueSnackbar({
              variant: "success",
              message: "Sezione Correttamente Aggiornata",
              action: (key: SnackbarKey) => {
                return (
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                );
              },
            });
            navigate(-1);
          },
          (err) => {
            setError(true);
          }
        );
      }
    },
  });

  useEffect(() => {
    const initWhistleblowing = () => {
      if (id && id !== "create") {
        getWhistleblowing({ id: +id }).then(
          ({ data }) => {
            getContentImage({ path: data?.whistleBlowing.imageUrl || "" }).then(
              ({ data: fileData }) => {
                if (fileData) {
                  const file = new File(
                    [fileData],
                    data?.whistleBlowing.imageUrl || "",
                    {
                      type: fileData.type,
                    }
                  );
                  setFile([
                    {
                      ...file,
                      name:
                        data?.whistleBlowing.imageUrl.split("/")[
                          data?.whistleBlowing.imageUrl.split("/").length - 1
                        ] || "",
                      uid: String(Date.now()),
                      status: "done",
                      url: URL.createObjectURL(file), // Crea un'anteprima
                    },
                  ]);
                }
              },
              (err) => console.log(err)
            );

            setWhistleblowing(data?.whistleBlowing);
          },
          () => {
            navigate(-1);
          }
        );
      }
    };
    initWhistleblowing();
  }, [getWhistleblowing, id, navigate, getContentImage]);

  useEffect(() => {
    if (whistleblowing) {
      formik.setFieldValue("title", whistleblowing.title);
      formik.setFieldValue("text", whistleblowing.text);
      formik.setFieldValue("buttonLabel", whistleblowing.buttonLabel);
      formik.setFieldValue("buttonLink", whistleblowing.buttonLink);
    }
  }, [whistleblowing]);

  const onFieldChange = useCallback(
    (field: string) => (value: string) => {
      formik.setFieldValue(field, value);
    },
    []
  );

  const handleActiveChange = useCallback((checked: boolean) => {
    formik.setFieldValue("active", checked);
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar
        onBack={() => navigate(-1)}
        title={`${
          id === "create" ? "Nuova " : "Modifica "
        }Sezione Whistleblowing`}
      />
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          maxWidth: "50%",
          justifyContent: "start",
        }}
      >
        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="title"
          placeholder="Titolo"
          name="title"
          disabled={formik.isSubmitting}
          value={formik.values.title || ""}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <ReactQuill
          modules={modules}
          formats={formats}
          theme="snow"
          placeholder="Descrizione"
          id="text"
          value={formik.values.text || ""}
          onChange={onFieldChange("text")}
        />

        <Upload {...props} fileList={file} listType="picture">
          <Button icon={<UploadOutlined />}>Carica Immagine</Button>
        </Upload>

        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="buttonLabel"
          placeholder="Etichetta Bottone"
          name="buttonLabel"
          disabled={formik.isSubmitting}
          value={formik.values.buttonLabel || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.buttonLabel && Boolean(formik.errors.buttonLabel)
          }
          helperText={formik.touched.buttonLabel && formik.errors.buttonLabel}
        />

        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="buttonLink"
          placeholder="URL Bottone"
          name="buttonLink"
          disabled={formik.isSubmitting}
          value={formik.values.buttonLink || ""}
          onChange={formik.handleChange}
          error={formik.touched.buttonLink && Boolean(formik.errors.buttonLink)}
          helperText={formik.touched.buttonLink && formik.errors.buttonLink}
        />

        <Space direction="vertical">
          <Switch
            checkedChildren="Attivo"
            unCheckedChildren="Disattivo"
            id="active"
            checked={formik.values.active}
            onChange={handleActiveChange}
          />
        </Space>

        <MaterialButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            Object.keys(formik.errors).length > 0 ||
            formik.isSubmitting ||
            !isFileModified
          }
          sx={{ mt: 3, mb: 2, backgroundColor: LightTheme.colors.primary }}
        >
          {formik.isSubmitting
            ? "Salvataggio..."
            : `${
                id === "create" ? "Salva " : "Modifica "
              }Sezione Whistleblowing`}
        </MaterialButton>
      </Box>
    </Container>
  );
}
