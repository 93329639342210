import { Button, Container, IconButton } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import { useCallback, useEffect, useState } from "react";
import { LightTheme } from "../../Providers/ThemeProvider";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import DynamicTable from "../../Components/Custom/VirtuosoTableComponent";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import "./TownView.css";
import { ColumnType } from "antd/es/table";
import { Tooltip } from "antd";
import { useLazyDeleteTownQuery, useLazyGetTownsQuery } from "../../api/Town";
import { Town } from "../../types/Town";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";

interface TableData {
  id: number;
  name: string;
  title: string;
  description: string;
  active: boolean;
}

interface TableColumnData extends ColumnType<TableData> {
  dataIndex: keyof TableData;
  title: string;
  width?: number;
}

export default function TownView() {
  const navigate = useNavigate();
  const [
    getTownsList,
    {
      data: townsList,
      isLoading: isTownsListLoading,
      isError: isTownsListError,
    },
  ] = useLazyGetTownsQuery();
  const [list, setList] = useState<Town[]>([]);
  const [deleteTown] = useLazyDeleteTownQuery();

  const initTowns = useCallback(() => {
    getTownsList({});
  }, [getTownsList]);

  useEffect(() => {
    initTowns();
  }, []);

  useEffect(() => {
    if (townsList) {
      setList(townsList.list);
    }
  }, [townsList]);

  const createTown = () => {
    navigate("create");
  };

  const updateTown = (id: number) => () => {
    navigate(`${id}`);
  };

  const renderAsyncContent = useCallback(() => {
    if (!isTownsListLoading && isTownsListError) {
      return enqueueSnackbar({
        variant: "error",
        message:
          "Errore durante la raccolta dei dati sulla Sezione richiesta, riprova più tardi...",
      });
    }

    const renderTextWithLimitedCharacters = (text: string) => {
      const sanitizedText = DOMPurify.sanitize(text);

      const limitedText = sanitizedText.slice(0, 20);

      const finalText =
        sanitizedText.length > 16 ? `${limitedText}...` : limitedText;

      return finalText;
    };

    const handleDeleteTownClick = (id: number) => () => {
      deleteTown({ id })
        .then(({ data }) => {
          enqueueSnackbar({
            variant: "success",
            message: "Comune Correttamente Eliminato",
            action: (key: SnackbarKey) => {
              return (
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => closeSnackbar(key)}
                >
                  <HighlightOffIcon />
                </IconButton>
              );
            },
          });
          initTowns();
        })
        .catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Impossibile eliminare il comune",
            action: (key: SnackbarKey) => {
              return (
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => closeSnackbar(key)}
                >
                  <HighlightOffIcon />
                </IconButton>
              );
            },
          });
        });
    };

    const columns: TableColumnData[] = [
      {
        width: 100,
        title: "ID",
        dataIndex: "id",
        align: "center",
        render: (text: any) => (
          <Tooltip placement="bottom" title={text}>
            <div onClick={updateTown(text)} style={{ paddingTop: "5%" }}>
              {text}
            </div>
          </Tooltip>
        ),
      },
      {
        width: 100,
        title: "Nome",
        dataIndex: "name",
        align: "center",
        render: (text: any) => (
          <Tooltip placement="bottom" title={text}>
            <div style={{ paddingTop: "5%" }}>{text}</div>
          </Tooltip>
        ),
      },
      {
        width: 100,
        title: "Titolo",
        dataIndex: "title",
        render: (text: string) => (
          <Tooltip
            placement="bottom"
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              ></div>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: renderTextWithLimitedCharacters(text),
              }}
            ></div>
          </Tooltip>
        ),
        align: "center",
      },
      {
        width: 100,
        title: "Descrizione",
        dataIndex: "description",
        render: (text: string) => (
          <Tooltip
            placement="bottom"
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              ></div>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: renderTextWithLimitedCharacters(text),
              }}
            ></div>
          </Tooltip>
        ),
        align: "center",
      },
      {
        width: 100,
        title: "Attivo",
        align: "center",
        dataIndex: "active",
        render: (active: boolean) => (
          <div style={{ paddingTop: "5%", color: active ? "green" : "red" }}>
            {active ? "Sì" : "No"}
          </div>
        ),
      },
      {
        width: 100,
        title: "Azioni",
        align: "center",
        dataIndex: "id",
        render: (id: number) => (
          <div style={{ paddingTop: "5%" }}>
            <Tooltip placement="bottom" title="Elimina Comune">
              <IconButton onClick={handleDeleteTownClick(id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];

    const rows: TableData[] = list.map((el) => {
      return {
        id: el.id,
        name: el.name,
        title: el.title,
        description: el.description,
        active: el.active,
      };
    });

    return (
      <DynamicTable<TableData>
        columns={columns}
        rows={rows}
        emptyMessage="Nessun Comune Presente"
        isLoading={isTownsListLoading}
      />
    );
  }, [isTownsListLoading, isTownsListError, list, updateTown]);

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar title="Sezione Comuni" />
      <Button
        onClick={createTown}
        variant="outlined"
        style={{
          color: LightTheme.colors.primary,
          borderColor: LightTheme.colors.primary,
        }}
      >
        Nuovo Comune
      </Button>
      <div style={{ marginTop: 24 }}>{renderAsyncContent()}</div>
    </Container>
  );
}
