import { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import ViewNotFound from "../Views/ViewNotFound";
import { useAuth } from "../Providers/AuthProvider";
import LoginView from "../Views/LoginView";
import DashboardView from "../Views/DashboardView";
import AdminRoot from "../Layout/AdminRoot";
import ConcerningsView from "../Views/ConcerningsView";
import ConcerningEdit from "../Views/ConcerningsView/ConcerningEdit";
import CertificationsView from "../Views/CertificationsView";
import CertificationEdit from "../Views/CertificationsView/CertificationEdit";
import SocialResponsibilityView from "../Views/SocialResponsibilityView";
import SocialResponsibilityEdit from "../Views/SocialResponsibilityView/SocialResponsibilityEdit";
import TownsView from "../Views/TownsView";
import TownEdit from "../Views/TownsView/TownEdit";
import WhistleBlowingView from "../Views/WhistleBlowingView";
import WhistleBlowingEdit from "../Views/WhistleBlowingView/WhistleBlowingEdit";
import TecnicalLinks from "../Views/HomeView/partials/TecnicalLinks";
import CertificazioniView from "../Views/CertificazioniView";
import ResponsabilitaSocialeView from "../Views/ResponsabilitaSocialeView";
import TownView from "../Views/HomeView/partials/TownView";
import TownDetailView from "../Views/HomeView/partials/TownDetailView";
const Root = lazy(() => import("../Layout/Root"));
const HomeView = lazy(() => import("../Views/HomeView/index"));
const PrivacyPolicyView = lazy(
  () => import("../Views/PrivacyPolicyView/index")
);
const CookiePolicyView = lazy(() => import("../Views/CookiePolicyView/index"));

const PrivateRoute = () => {
  let { user, isReady } = useAuth();
  let location = useLocation();

  if (isReady && !user) {
    // Se l'utente non è autenticato, redirige sempre a "/admin/login"
    return <Navigate to={"/login"} state={{ from: location }} />;
  }
  // Se tutto è valido, consenti l'accesso alla rotta corrente
  return <Outlet />;
};

const router = createBrowserRouter([
  {
    path: "login",
    Component: () => {
      let { user, isReady } = useAuth();
      let location = useLocation();

      if (isReady && user) {
        // Se l'utente non è autenticato, redirige sempre a "/admin/login"
        return <Navigate to={"/admin/dashboard"} state={{ from: location }} />;
      }
      return <LoginView />;
    },
  },
  {
    path: "admin",
    Component: PrivateRoute,
    children: [
      {
        path: "",
        Component: AdminRoot,
        children: [
          {
            path: "dashboard",
            Component: DashboardView,
          },
          {
            path: "about-us",
            element: <Outlet />,
            children: [
              { index: true, Component: ConcerningsView },
              {
                path: ":id",
                Component: ConcerningEdit,
              },
            ],
          },
          {
            path: "certifications",
            element: <Outlet />,
            children: [
              { index: true, Component: CertificationsView },
              {
                path: ":id",
                Component: CertificationEdit,
              },
            ],
          },
          {
            path: "social-responsibility",
            element: <Outlet />,
            children: [
              { index: true, Component: SocialResponsibilityView },
              {
                path: ":id",
                Component: SocialResponsibilityEdit,
              },
            ],
          },
          {
            path: "towns",
            element: <Outlet />,
            children: [
              { index: true, Component: TownsView },
              {
                path: ":id",
                Component: TownEdit,
              },
            ],
          },
          {
            path: "whistleblowing",
            element: <Outlet />,
            children: [
              { index: true, Component: WhistleBlowingView },
              {
                path: ":id",
                Component: WhistleBlowingEdit,
              },
            ],
          },
          {
            path: "",
            Component: () => <Navigate to="/admin/dashboard" />,
          },
        ],
      },
      {
        path: "*",
        Component: () => {
          const { signOut } = useAuth();
          signOut();
          return <Navigate to={"/login"} />;
        },
      },
    ],
  },
  {
    path: "",
    Component: Root,
    children: [
      {
        path: "",
        Component: HomeView,
        children: [],
      },
      {
        path: "/link-tecnici",
        Component: TecnicalLinks,
        children: [],
      },
      {
        path: "/certificazioni",
        Component: CertificazioniView,
        children: [],
      },
      {
        path: "/comuni",
        element: <Outlet />,
        children: [
          { index: true, Component: TownView },
          {
            path: ":name",
            Component: TownDetailView,
            children: [],
          },
        ],
      },
      {
        path: "/responsabilita-sociale",
        Component: ResponsabilitaSocialeView,
        children: [],
      },
    ],
  },
  {
    path: "/privacy-policy",
    Component: PrivacyPolicyView,
    children: [],
  },
  {
    path: "/cookie-policy",
    Component: CookiePolicyView,
    children: [],
  },
  {
    path: "/404",
    Component: ViewNotFound,
  },
  {
    path: "*",
    Component: () => <Navigate to={"/404"} />,
  },
]);

export default router;
