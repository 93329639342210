import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./redux/slices/errorSlice";
import { Concernings } from "./api/Concernings";
import { Certifications } from "./api/Certifications";
import { Towns } from "./api/Town";
import { Whistleblowings } from "./api/Whistleblowing";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    [Concernings.reducerPath]: Concernings.reducer,
    [Certifications.reducerPath]: Certifications.reducer,
    [Towns.reducerPath]: Towns.reducer,
    [Whistleblowings.reducerPath]: Whistleblowings.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      Concernings.middleware,
      Certifications.middleware,
      Towns.middleware,
      Whistleblowings.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
