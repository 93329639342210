import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import { ThemeProvider, useTheme } from "./Providers/ThemeProvider";
import { CssBaseline } from "@mui/material";
import { Suspense } from "react";
import { TailSpin } from "react-loader-spinner";
import CustomSnackbarProvider from "./Providers/CustomSnackbarProvider";

function App() {
  const { theme } = useTheme();

  return (
    <ThemeProvider>
      <CssBaseline />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "25%",
            }}
          >
            <TailSpin
              visible={true}
              height={80}
              width={80}
              color={theme.colors.primary}
              ariaLabel="Loading..."
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        }
      >
        <CustomSnackbarProvider />
        <RouterProvider router={router} />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
