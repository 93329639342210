import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { Box, Container, IconButton, TextField } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import { useLazyGetImageSignedUrlQuery } from "../../api/Concernings";
import * as yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CertificationsView.css";
import { Button, List, Upload, UploadFile, UploadProps } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button as MaterialButton } from "@mui/material";
import { LightTheme } from "../../Providers/ThemeProvider";
import {
  useLazyCreateCertificationQuery,
  useLazyGetCertificationQuery,
  useLazyUpdateCertificationQuery,
} from "../../api/Certifications";
import { Certification } from "../../types/Certifications";

export default function CertificationEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [certification, setCertification] = useState<Certification>();
  const [image, setImage] = useState<UploadFile[]>([]);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [error, setError] = useState(false);
  const [isFileModified, setIsFileModified] = useState(false);
  const [isImageModified, setIsImageModified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getCertification] = useLazyGetCertificationQuery();
  const [createCertification] = useLazyCreateCertificationQuery();
  const [getContentImage] = useLazyGetImageSignedUrlQuery();
  const [updateCertification] = useLazyUpdateCertificationQuery();
  const validationSchema = yup.object({
    title: yup.string().required("Titolo: Campo Obbligatorio"),
    text: yup.string().required("Testo: Campo Obbligatorio"),
    active: yup.boolean(),
  });
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      ["link"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
  ];
  const props: UploadProps = {
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        if (onSuccess) onSuccess("ok");
      }, 1000);
    },
    beforeUpload: (file) => {
      const isImg = file.type.startsWith("image");
      if (!isImg) {
        enqueueSnackbar({
          variant: "error",
          message: "Puoi inserire solo file immagine",
          action: (key: SnackbarKey) => {
            return (
              <IconButton
                style={{ color: "white" }}
                onClick={() => closeSnackbar(key)}
              >
                <HighlightOffIcon />
              </IconButton>
            );
          },
        });
      }
      return isImg || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (info.file.status !== "removed") {
        setImage([
          {
            originFileObj: info.file.originFileObj,
            uid: String(Date.now()),
            status: "done",
            name: info.file.name,
            url: URL.createObjectURL(info.file.originFileObj as File), // Crea un'anteprima
          },
        ]);
      } else {
        setImage([]);
      }
      setIsImageModified(true);
    },
  };

  const fileProps: UploadProps = {
    fileList: files,
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        if (onSuccess) onSuccess("ok");
      }, 1000);
    },
    onChange: (info) => {
      setFiles(info.fileList);
      setIsFileModified(true);
    },
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      title: undefined,
      text: undefined,
    },
    onSubmit: ({ text, title }) => {
      const formData = new FormData();
      if (title) formData.append("title", title);
      if (text) formData.append("text", text);
      if (image && image[0].originFileObj) {
        formData.append("image", image[0].originFileObj as File);
      }
      if (files) {
        files.forEach((file) => {
          formData.append("files", file.originFileObj as File);
        });
      }
      if (id === "create") {
        createCertification(formData).then(
          ({ data }) => {
            enqueueSnackbar({
              variant: "success",
              message: "Sezione Correttamente Creata",
              action: (key: SnackbarKey) => {
                return (
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                );
              },
            });
            navigate(-1);
          },
          (err) => {
            setError(true);
          }
        );
        formik.isSubmitting = false;
      } else {
        updateCertification({ id: +id!, fd: formData }).then(
          ({ data }) => {
            enqueueSnackbar({
              variant: "success",
              message: "Sezione Correttamente Aggiornata",
              action: (key: SnackbarKey) => {
                return (
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                );
              },
            });
            navigate(-1);
          },
          (err) => {
            setError(true);
          }
        );
      }
    },
  });

  useEffect(() => {
    const initCertification = () => {
      if (id && id !== "create") {
        getCertification({ id: +id }).then(
          ({ data }) => {
            getContentImage({ path: data?.certification.imageUrl || "" }).then(
              ({ data: fileData }) => {
                if (fileData) {
                  const file = new File(
                    [fileData],
                    data?.certification.imageUrl || "",
                    {
                      type: fileData.type,
                    }
                  );
                  setImage([
                    {
                      ...file,
                      name:
                        data?.certification.imageUrl.split("/")[
                          data?.certification.imageUrl.split("/").length - 1
                        ] || "",
                      uid: String(Date.now()),
                      status: "done",
                      url: URL.createObjectURL(file), // Crea un'anteprima
                    },
                  ]);
                }
              },
              (err) => console.log(err)
            );

            const files = data?.certification.files;

            if (files) {
              try {
                const parsedFiles: string[] = JSON.parse(files);

                const filesArray: UploadFile[] = [];
                // Creiamo un array di Promises per ottenere le immagini
                const promises = parsedFiles.map((fileUrl) =>
                  getContentImage({ path: fileUrl }).then(
                    ({ data: fileData }) => {
                      if (fileData) {
                        const file = new File([fileData], fileUrl, {
                          type: fileData.type,
                        });
                        filesArray.push({
                          originFileObj: file,
                          name: fileUrl.split("/").pop() || "",
                          uid: String(Date.now() + Math.random()), // Evitiamo duplicati
                          status: "done",
                          type: "file",
                          url: URL.createObjectURL(file),
                        } as UploadFile);
                      }
                    }
                  )
                );

                // Aspettiamo tutte le richieste e filtriamo eventuali `null`
                Promise.all(promises).then((result) => {
                  setFiles(filesArray); // Rimuoviamo eventuali `null`
                });
              } catch (error) {
                console.error("Errore nel parsing dei file:", error);
              }
            }

            setCertification(data?.certification);
          },
          () => {
            navigate(-1);
          }
        );
      }
    };
    initCertification();
  }, [getCertification, id, navigate, getContentImage]);

  useEffect(() => {
    if (certification) {
      formik.setFieldValue("title", certification.title);
      formik.setFieldValue("text", certification.text);
    }
  }, [certification]);

  const onTextChange = useCallback((value: string) => {
    formik.setFieldValue("text", value);
  }, []);

  const handleDownload = (file: UploadFile) => {
    const link = document.createElement("a");
    link.href = file.url || file.thumbUrl || "";
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar
        onBack={() => navigate(-1)}
        title={`${id === "create" ? "Nuova " : "Modifica "}Certificazione`}
      />
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          maxWidth: "50%",
          justifyContent: "start",
        }}
      >
        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="title"
          placeholder="Titolo"
          name="title"
          disabled={formik.isSubmitting}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <ReactQuill
          modules={modules}
          formats={formats}
          theme="snow"
          placeholder="Descrizione"
          id="text2"
          value={formik.values.text}
          onChange={onTextChange}
        />

        <Upload {...props} fileList={image} listType="picture">
          <Button icon={<UploadOutlined />}>Carica Immagine</Button>
        </Upload>

        <Upload
          {...fileProps}
          listType="picture"
          itemRender={(originNode, file) => (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {originNode}
              <Button
                title="Scarica"
                icon={<DownloadOutlined />}
                onClick={() => handleDownload(file)}
              >
                Scarica
              </Button>
            </div>
          )}
        >
          <Button icon={<UploadOutlined />}>
            Carica File (anche più di uno)
          </Button>
        </Upload>

        <MaterialButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            (formik.isSubmitting ||
              error ||
              !formik.dirty ||
              Object.keys(formik.errors).length > 0) &&
            !isFileModified &&
            !isImageModified
          }
          sx={{ mt: 3, mb: 2, backgroundColor: LightTheme.colors.primary }}
        >
          {formik.isSubmitting
            ? "Salvataggio..."
            : `${id === "create" ? "Salva " : "Modifica "}Certificazione`}
        </MaterialButton>
      </Box>
    </Container>
  );
}
