import { Button, Container, IconButton } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import { useCallback, useEffect, useState } from "react";
import { LightTheme } from "../../Providers/ThemeProvider";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import DynamicTable from "../../Components/Custom/VirtuosoTableComponent";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import "./CertificationsView.css";
import { ColumnType } from "antd/es/table";
import { Tooltip } from "antd";
import {
  useLazyDeleteCertificationQuery,
  useLazyGetCertificationsQuery,
} from "../../api/Certifications";
import { Certification } from "../../types/Certifications";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";

interface TableData {
  id: number;
  title: string;
  text: string;
}

interface TableColumnData extends ColumnType<TableData> {
  dataIndex: keyof TableData;
  title: string;
  width?: number;
}

export default function CertificationsView() {
  const navigate = useNavigate();
  const [
    getCertificationsList,
    {
      data: certificationsList,
      isLoading: isCertificationsListLoading,
      isError: isCertificationsListError,
    },
  ] = useLazyGetCertificationsQuery();
  const [list, setList] = useState<Certification[]>([]);
  const [deleteCertification] = useLazyDeleteCertificationQuery();

  const initCertifications = useCallback(() => {
    getCertificationsList({});
  }, [getCertificationsList]);

  useEffect(() => {
    initCertifications();
  }, []);

  useEffect(() => {
    if (certificationsList) {
      setList(certificationsList.list);
    }
  }, [certificationsList]);

  const createCertification = () => {
    navigate("create");
  };

  const updateCertification = (id: number) => () => {
    navigate(`${id}`);
  };

  const renderAsyncContent = useCallback(() => {
    if (!isCertificationsListLoading && isCertificationsListError) {
      return enqueueSnackbar({
        variant: "error",
        message:
          "Errore durante la raccolta dei dati sulla Sezione richiesta, riprova più tardi...",
      });
    }

    const renderTextWithLimitedCharacters = (text: string) => {
      const sanitizedText = DOMPurify.sanitize(text);

      const limitedText = sanitizedText.slice(0, 20);

      const finalText =
        sanitizedText.length > 16 ? `${limitedText}...` : limitedText;

      return finalText;
    };

    const handleDeleteCertificationClick = (id: number) => () => {
      deleteCertification({ id })
        .then(({ data }) => {
          enqueueSnackbar({
            variant: "success",
            message: "Sezione Correttamente Eliminata",
            action: (key: SnackbarKey) => {
              return (
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => closeSnackbar(key)}
                >
                  <HighlightOffIcon />
                </IconButton>
              );
            },
          });
          initCertifications();
        })
        .catch(() => {
          enqueueSnackbar({
            variant: "error",
            message: "Impossibile eliminare la sezione",
            action: (key: SnackbarKey) => {
              return (
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => closeSnackbar(key)}
                >
                  <HighlightOffIcon />
                </IconButton>
              );
            },
          });
        });
    };

    const columns: TableColumnData[] = [
      {
        width: 100,
        title: "ID",
        dataIndex: "id",
        align: "center",
        render: (text: any) => (
          <Tooltip placement="bottom" title={text}>
            <div
              onClick={updateCertification(text)}
              style={{ paddingTop: "5%" }}
            >
              {text}
            </div>
          </Tooltip>
        ),
      },
      {
        width: 100,
        title: "Titolo",
        dataIndex: "title",
        align: "center",
        render: (text: any) => (
          <Tooltip placement="bottom" title={text}>
            <div style={{ paddingTop: "5%" }}>{text}</div>
          </Tooltip>
        ),
      },
      {
        width: 100,
        title: "Testo",
        dataIndex: "text",
        render: (text: string) => (
          <Tooltip
            placement="bottom"
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              ></div>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: renderTextWithLimitedCharacters(text),
              }}
            ></div>
          </Tooltip>
        ),
        align: "center",
      },
      {
        width: 100,
        title: "Azioni",
        align: "center",
        dataIndex: "id",
        render: (id: number) => (
          <div style={{ paddingTop: "12.5%" }}>
            <Tooltip placement="bottom" title="Elimina Sezione">
              <IconButton onClick={handleDeleteCertificationClick(id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];

    const rows: TableData[] = list.map((el) => {
      return {
        id: el.id,
        title: el.title,
        text: el.text,
      };
    });

    return (
      <DynamicTable<TableData>
        columns={columns}
        rows={rows}
        emptyMessage="Nessuna Certificazione Presente"
        isLoading={isCertificationsListLoading}
      />
    );
  }, [
    isCertificationsListLoading,
    isCertificationsListError,
    list,
    updateCertification,
  ]);

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar title='Sezione "Certificazioni"' />
      <Button
        onClick={createCertification}
        variant="outlined"
        style={{
          color: LightTheme.colors.primary,
          borderColor: LightTheme.colors.primary,
        }}
      >
        Nuovo Elemento
      </Button>
      <div style={{ marginTop: 24 }}>{renderAsyncContent()}</div>
    </Container>
  );
}
