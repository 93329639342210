import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";
import Vectors404 from "./partials/Vectors404";
import { useTheme } from "../../Providers/ThemeProvider";


const ViewNotFound: FC = memo((): JSX.Element => {

    const { theme } = useTheme();

    const navigate = useNavigate();

    const onClickGoHome = () => {
        navigate('/');
    };

    return (
        <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', gap: 16, textAlign: "center", alignItems: 'center', justifyContent: 'center', marginTop: 40, marginBottom: 32 }}>
            <Vectors404 />
            <Typography variant={'h3'} gutterBottom>
                Pagina non trovata
            </Typography>
            <Button variant="contained" style={{ backgroundColor: theme.colors.primary }} onClick={ onClickGoHome }>
                Torna alla Home
            </Button>
        </Container>
    );
});

ViewNotFound.displayName = 'ViewNotFound';

export default ViewNotFound;
