import { Box, useMediaQuery } from "@mui/material";
import image3 from "../../../assets/img/carousel/Slide-Ecoburgus-scaled.jpg";
import { bpTheme } from "../../../Providers/ThemeProvider";
import { useLazyGetTownsQuery } from "../../../api/Town";
import { useEffect, useState } from "react";
import { Town } from "../../../types/Town";
import { useNavigate } from "react-router-dom";

export default function TownView() {
  const navigate = useNavigate();
  const isSm = useMediaQuery(bpTheme.breakpoints.down("sm"));
  const [getTowns] = useLazyGetTownsQuery();
  const [towns, setTowns] = useState<Town[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTowns({}).then(
      ({ data }) => {
        if (data) {
          setTowns(data.list);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
        setTowns([]);
      }
    );
  }, [getTowns]);

  const handleTownNavigate =
    (url: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(`${url}`);
    };

  return (
    <div
      style={{
        position: "relative",
        height: "80vh",
        width: "100%",
        overflow: "auto",
      }}
      className="slider-container"
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: `100%`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${image3})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="image"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          paddingLeft: 32,
          top: "20%",
          color: "#FFF",
          fontFamily: '"Roboto" sans-serif',
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Box
          style={{
            textTransform: "uppercase",
            fontSize: "14px",
            fontWeight: 600,
            letterSpacing: "12px",
          }}
        >
          Eco Burgus
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 48,
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "0.9em",
            color: "#FFF",
          }}
        >
          <span style={{ color: "#FFF" }}>I Comuni in cui operiamo</span>
          {!loading && towns.length === 0 ? (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <p className="h4">Nessun Comune Presente in Archivio</p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: isSm ? "column" : "row",
                gap: 32,
              }}
            >
              {towns.map((town, index) => (
                <span key={index}>
                  <a
                    onClick={handleTownNavigate(town.name)}
                    target="_blank"
                    rel="noopener"
                    className="my-btn"
                  >
                    {town.name}
                  </a>
                </span>
              ))}
            </div>
          )}
        </Box>
      </div>
    </div>
  );
}
