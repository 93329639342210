import image3 from "../../../assets/img/carousel/ecoburgus.jpeg";
import { Box, useMediaQuery } from "@mui/material";
import "../HomeView.css";
import { bpTheme } from "../../../Providers/ThemeProvider";

export default function TecnicalLinks() {
  const isSm = useMediaQuery(bpTheme.breakpoints.down("sm"));

  return (
    <>
      <div
        id="/"
        style={{
          position: "relative",
          height: "100vh",
          width: "100%",
          overflow: "auto",
        }}
        className="slider-container"
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: `100%`,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${image3})`,
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="image"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            paddingLeft: 32,
            top: "20%",
            color: "#FFF",
            fontFamily: '"Roboto" sans-serif',
            display: "flex",
            flexDirection: "column",
            gap: 32,
          }}
        >
          <Box
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: 600,
              letterSpacing: "12px",
            }}
          >
            Eco Burgus
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 48,
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "0.9em",
              color: "#FFF",
            }}
          >
            <span style={{ color: "#FFF" }}>
              Link Tecnici
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: isSm ? "column" : "row",
                gap: 32,
              }}
            >
              <span>
                <a href="/" target="_blank" rel="noopener" className="my-btn">
                  Certificazioni
                </a>
              </span>
              <span>
                <a href="/" target="_blank" rel="noopener" className="my-btn">
                  Responsabilità Sociale
                </a>
              </span>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
