import { FC, memo, ReactNode } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
interface PropsMenuItem {
  label: string;
  Icon?: ReactNode;
}

const UIMenuItem: FC<PropsMenuItem> = ({ label, Icon }): JSX.Element => (
  <ListItemButton>
    {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
    <ListItemText primary={label} />
  </ListItemButton>
);

export default memo(UIMenuItem);
