import { Paper } from "@mui/material";
import { Table as AntTable, Empty } from "antd";
import type { TableProps } from "antd";

interface DynamicTableProps<T> {
  columns: TableProps<T>["columns"];
  rows: T[];
  isLoading?: boolean;
  emptyMessage?: string;
}

const DynamicTable = <T extends {}>({
  columns,
  rows,
  isLoading,
  emptyMessage,
}: DynamicTableProps<T>) => {
  const fixedColumns: TableProps<T>["columns"] = columns;

  return (
    <Paper style={{ height: "auto", maxHeight: 400, width: "100%", cursor: 'pointer' }}>
      <AntTable<T>
        bordered={false}
        virtual
        columns={fixedColumns}
        scroll={{ y: 400 }}
        rowKey="id"
        loading={isLoading}
        dataSource={!isLoading && rows.length === 0 ? [] : rows}
        locale={{
          emptyText: isLoading ? "" : <Empty description={emptyMessage} />,
        }}
        pagination={false}
      />
    </Paper>
  );
};

export default DynamicTable;
