import { FC, MouseEvent, useState } from "react";
import { useAuth } from "../../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { PASSWORD_REQUIRED, USERNAME_REQUIRED } from "../../types/messages";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setError } from "../../redux/slices/errorSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "../../Providers/ThemeProvider";

const LoginView: FC = () => {
  let auth = useAuth();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const validationSchema = yup.object({
    username: yup.string().required(USERNAME_REQUIRED),
    password: yup.string().required(PASSWORD_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ username, password }) => {
      auth.signIn(username, password, (success, failure) => {
        if (success) {
          navigate("/admin/dashboard");
        } else {
          dispatch(setError(failure?.message || ""));
        }
        formik.setSubmitting(false);
      });
    },
  });

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          LOGIN
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            disabled={formik.isSubmitting}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
          <TextField
            margin="normal"
            type={showPassword ? "text" : "password"}
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            disabled={formik.isSubmitting}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    title={
                      showPassword ? "Nascondi password" : "Mostra password"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting}
            sx={{ mt: 3, mb: 2, backgroundColor: theme.colors.primary }}
          >
            {formik.isSubmitting ? "Loading" : "Login"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

LoginView.displayName = "LoginView";
export default LoginView;
