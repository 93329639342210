import { Container, CardContent, Grid, styled, Card } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import { useAuth } from "../../Providers/AuthProvider";
import { IMenuItem } from "../../types/IMenuItem";
import { LightTheme } from "../../Providers/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const myPages: IMenuItem[] = [
  {
    label: "Certificazioni",
    to: "/certifications",
  },
  {
    label: "Sezione Cosa Facciamo",
    to: "/about-us",
  },
  {
    label: "Sezione Resp. Sociale",
    to: "/social-responsibility",
  },
  {
    label: "Sezione Comuni",
    to: "/towns",
  },
  {
    label: "Sezione Whistleblowing",
    to: "/whistleblowing",
  },
];

const StyledCard = styled(Card)(() => ({
  cursor: 'pointer',
  backgroundColor: LightTheme.colors.primary,
  borderRadius: '60px',
  color: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
  transition: 'background-color .2s ease-in',
  '&:hover': {
    backgroundColor: LightTheme.colors.secondaryButton,
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)',
  },
}));

export default function DashboardView() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const navigateTo = useCallback((route: string) => () => {
    navigate(route);
  }, [navigate]);

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar
        title={`Bentornato, ${user?.nome} ${user?.cognome}!`}
        subtitle={"Seleziona una delle seguenti voci per iniziare"}
      />
      <Grid
        container
        spacing={3}
        alignItems={"stretch"}
        justifyContent={"start"}
      >
        {myPages.map((page, index) => (
            <Grid key={index} item xs={12} md={3}>
              <StyledCard
                onClick={navigateTo(`/admin${page.to}`)}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  //backgroundColor: theme.palette.secondary.dark,
                  //color: 'white'
                }}
              >
                <CardContent>{page.label}</CardContent>
              </StyledCard>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
