import { FC, memo } from 'react';

const Vectors404: FC = memo((): JSX.Element => {
    return (
        <img 
            src={ require('../../../assets/img/error-404.png') } 
            alt="404"
            style={{ width: 350, height: 350, padding: 32, }}
        />
    );
});
Vectors404.displayName = 'Vectors404';

export default Vectors404;
