import Axios from "axios";

const userClient = Axios.create();

userClient.interceptors.request.use((config) => {
  if (config.url?.includes("login")) {
    return config;
  }

  const token = sessionStorage.getItem("Access-Token");

  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token).toString()}`;
  }

  return config;
});

userClient.interceptors.response.use(
  (res) => res,
  (err) => {
    const res = err.response;
    if (
      res &&
      !res.config.url.includes("login") &&
      !res.config.url.includes("token")
    ) {
      if (res.status === 401 || res.status === 403) {
        setTimeout(() => {
          sessionStorage.removeItem("Access-Token");
          sessionStorage.removeItem("User");
          sessionStorage.removeItem("Refresh-Token");
          window.location.href = "/login";
        }, 2000);
      }
    }
    return Promise.reject(err);
  }
);

export default userClient;
