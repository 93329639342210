import { createTheme } from '@mui/material';
import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface Theme {
    colors: {
      primary: string;
      secondary: string;
      secondaryButton: string;
      secondaryButtonHover: string;
      secondaryText: string;
      background: string;
      text: string;
      buttonText: string;
      buttonTextSecondary: string;
      disabled: string;
    };
}

export const LightTheme: Theme = {
    colors: {
        primary: '#408432',
        secondaryButton: '#8FBC2B',
        secondaryButtonHover: '#7AA526',
        secondary: '#F7F7F7',
        secondaryText: '#408432',
        buttonText: '#F7F7F7',
        buttonTextSecondary: '#408432',
        background: '#F7F7F7',
        disabled: '#F2F2F2',
        text: '#333',
    },
};

export const DarkTheme: Theme = {
    colors: {
        primary: '#408432',
        secondary: '#121212',
        secondaryButton: '#8FBC2B',
        secondaryButtonHover: '#7AA526',
        secondaryText: '#408432',
        buttonTextSecondary: '#408432',
        buttonText: '#F7F7F7',
        background: '#121212',
        disabled: '#121212',
        text: '#F7F7F7',
    },
};

const ThemeContext = createContext<{
    theme: Theme,
    setTheme: (theme: Theme) => void;
}>({
    theme: LightTheme,
    setTheme: () => {},
});

export const bpTheme = createTheme({});

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(LightTheme);

    return (
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {children}
      </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
