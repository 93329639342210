import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { Box, Container, IconButton, TextField } from "@mui/material";
import PageToolbar from "../../Components/Custom/PageToolbar";
import * as yup from "yup";
import { useFormik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TownView.css";
import {
  Button,
  Input,
  Space,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { Button as MaterialButton } from "@mui/material";
import { LightTheme } from "../../Providers/ThemeProvider";
import { Town } from "../../types/Town";
import {
  useLazyCreateButtonQuery,
  useLazyCreateLinkQuery,
  useLazyCreateTownQuery,
  useLazyDeleteButtonQuery,
  useLazyDeleteLinkQuery,
  useLazyGetButtonsQuery,
  useLazyGetLinksQuery,
  useLazyGetTownQuery,
  useLazyUpdateButtonQuery,
  useLazyUpdateLinkQuery,
  useLazyUpdateTownQuery,
} from "../../api/Town";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import { useLazyGetImageSignedUrlQuery } from "../../api/Concernings";

export default function TownEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [town, setTown] = useState<Town>();
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getTown] = useLazyGetTownQuery();
  const [getLinks] = useLazyGetLinksQuery();
  const [getButtons] = useLazyGetButtonsQuery();
  const [createTown] = useLazyCreateTownQuery();
  const [updateTown] = useLazyUpdateTownQuery();
  const [createLink] = useLazyCreateLinkQuery();
  const [createButton] = useLazyCreateButtonQuery();
  const [updateLink] = useLazyUpdateLinkQuery();
  const [updateButton] = useLazyUpdateButtonQuery();
  const [deleteLink] = useLazyDeleteLinkQuery();
  const [deleteButton] = useLazyDeleteButtonQuery();
  const [getContentImage] = useLazyGetImageSignedUrlQuery();
  const [links, setLinks] = useState<
    { id?: number; label: string; url: string }[]
  >([]);
  const [linksLoading, setLinksLoading] = useState(true);
  const [buttonsLoading, setButtonsLoading] = useState(true);
  const [buttons, setButtons] = useState<
    { id?: number; label: string; file: UploadFile[] }[]
  >([]);
  const [linksEdited, setLinksEdited] = useState(false);
  const [buttonsEdited, setButtonsEdited] = useState(false);
  const validationSchema = yup.object({
    name: yup.string().required("Nome: Campo Obbligatorio"),
    title: yup.string().required("Titolo: Campo Obbligatorio"),
    description: yup.string().required("Descrizione: Campo Obbligatorio"),
    active: yup.boolean(),
  });
  const initialLinksRef = useRef(links);
  const initialButtonsRef = useRef(buttons);

  const fileProps: UploadProps = {
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        if (onSuccess) onSuccess("ok");
      }, 1000);
    },
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      ["link"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
  ];

  const handleFileChange = (index: number) => (info: any) => {
    setButtons((prevButtons) =>
      prevButtons.map((btn, i) =>
        i === index
          ? { ...btn, file: [info.fileList[info.fileList.length - 1]] }
          : btn
      )
    );
    setButtonsEdited(true);
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      title: "",
      description: "",
      active: false,
    },
    onSubmit: ({ name, title, description, active }) => {
      if (id === "create") {
        const payload = {
          name,
          title,
          description,
          active,
        };

        createTown(payload).then(({ data }) => {
          const oldLinks = initialLinksRef.current;
          const newLinks = links;

          const deletedLinks = oldLinks.filter((oldLink) => {
            return (
              oldLink.id &&
              !newLinks.some((newLink) => newLink.id === oldLink.id)
            );
          });

          const createdLinks = newLinks.filter((newLink) => !newLink.id);

          const updatedLinks = newLinks.filter((newLink) => {
            const oldLink = oldLinks.find((old) => old.id === newLink.id);
            return (
              oldLink &&
              (oldLink.label !== newLink.label || oldLink.url !== newLink.url)
            );
          });

          const oldButtons = initialButtonsRef.current;
          const newButtons = buttons;

          const deletedButtons = oldButtons.filter((oldButton) => {
            return (
              oldButton.id &&
              !newButtons.some((newButton) => newButton.id === oldButton.id)
            );
          });

          const createdButtons = newButtons.filter(
            (newButton) => !newButton.id
          );

          const updatedButtons = newButtons.filter((newButton) => {
            const oldButton = oldButtons.find((old) => old.id === newButton.id);
            return (
              oldButton &&
              (oldButton.label !== newButton.label ||
                oldButton.file !== newButton.file)
            );
          });

          try {
            Promise.all([
              ...deletedLinks.map((link) => deleteLink({ id: link.id! })),
              ...createdLinks.map((link) =>
                createLink({
                  label: link.label,
                  url: link.url,
                  townId: data?.town.id || 0,
                })
              ),
              ...updatedLinks.map((link) =>
                updateLink({
                  id: link.id!,
                  body: {
                    label: link.label,
                    url: link.url,
                  },
                })
              ),
              ...deletedButtons.map((button) =>
                deleteButton({ id: button.id! })
              ),
              ...createdButtons.map((button) => {
                const fd = new FormData();
                fd.append("label", button.label);
                fd.append("document", button.file[0].originFileObj as File);
                fd.append("townId", data!.town.id.toString());
                return createButton(fd);
              }),
              ...updatedButtons.map((button) => {
                const fd = new FormData();
                fd.append("label", button.label);
                fd.append("document", button.file[0].originFileObj as File);
                return updateButton({ id: button.id!, body: fd });
              }),
            ]);
            initialLinksRef.current = [...newLinks];
            setLinks(newLinks);
            initialButtonsRef.current = [...newButtons];
            setButtons(newButtons);
          } catch (e) {
            enqueueSnackbar({
              variant: "error",
              message:
                "Errore nella Creazione/Modifica dei Link o dei Pulsanti",
              action: (key: SnackbarKey) => {
                return (
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                );
              },
            });
          }

          enqueueSnackbar({
            variant: "success",
            message: "Comune Correttamente Inserito",
            action: (key: SnackbarKey) => {
              return (
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => closeSnackbar(key)}
                >
                  <HighlightOffIcon />
                </IconButton>
              );
            },
          });
          navigate(-1);
        });
      } else {
        const payload = {
          name,
          title,
          description,
          active,
        };
        updateTown({ id: +id!, body: payload }).then(
          ({ data }) => {
            const oldLinks = initialLinksRef.current;
            const newLinks = links;

            const deletedLinks = oldLinks.filter((oldLink) => {
              return (
                oldLink.id &&
                !newLinks.some((newLink) => newLink.id === oldLink.id)
              );
            });

            const createdLinks = newLinks.filter((newLink) => !newLink.id);

            const updatedLinks = newLinks.filter((newLink) => {
              const oldLink = oldLinks.find((old) => old.id === newLink.id);
              return (
                oldLink &&
                (oldLink.label !== newLink.label || oldLink.url !== newLink.url)
              );
            });

            const oldButtons = initialButtonsRef.current;
            const newButtons = buttons;

            const deletedButtons = oldButtons.filter((oldButton) => {
              return (
                oldButton.id &&
                !newButtons.some((newButton) => newButton.id === oldButton.id)
              );
            });

            console.log(deletedButtons);

            const createdButtons = newButtons.filter(
              (newButton) => !newButton.id
            );

            const updatedButtons = newButtons.filter((newButton) => {
              const oldButton = oldButtons.find(
                (old) => old.id === newButton.id
              );
              return (
                oldButton &&
                (oldButton.label !== newButton.label ||
                  oldButton.file !== newButton.file)
              );
            });

            try {
              Promise.all([
                ...deletedLinks.map((link) => deleteLink({ id: link.id! })),
                ...createdLinks.map((link) =>
                  createLink({
                    label: link.label,
                    url: link.url,
                    townId: data?.modified.id || 0,
                  })
                ),
                ...updatedLinks.map((link) =>
                  updateLink({
                    id: link.id!,
                    body: { label: link.label, url: link.url },
                  })
                ),
                ...deletedButtons.map((button) =>
                  deleteButton({ id: button.id! })
                ),
                ...createdButtons.map((button) => {
                  const fd = new FormData();
                  fd.append("label", button.label);
                  fd.append("document", button.file[0].originFileObj as File);
                  fd.append("townId", data!.modified.id.toString());
                  return createButton(fd);
                }),
                ...updatedButtons.map((button) => {
                  const fd = new FormData();
                  fd.append("label", button.label);
                  fd.append("document", button.file[0].originFileObj as File);
                  return updateButton({ id: button.id!, body: fd });
                }),
              ]);
              initialLinksRef.current = [...newLinks];
              setLinks(newLinks);
              initialButtonsRef.current = [...newButtons];
              setButtons(newButtons);
            } catch (e) {
              enqueueSnackbar({
                variant: "error",
                message:
                  "Errore nella Creazione/Modifica dei Link o dei Pulsanti",
                action: (key: SnackbarKey) => {
                  return (
                    <IconButton
                      style={{ color: "white" }}
                      onClick={() => closeSnackbar(key)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  );
                },
              });
            }

            enqueueSnackbar({
              variant: "success",
              message: "Comune Correttamente Aggiornato",
              action: (key: SnackbarKey) => {
                return (
                  <IconButton
                    style={{ color: "white" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                );
              },
            });
            navigate(-1);
          },
          (err) => {
            setError(true);
          }
        );
      }
    },
  });

  useEffect(() => {
    const initTown = () => {
      if (id && id !== "create") {
        getTown({ id: +id }).then(
          ({ data }) => {
            setTown(data?.town);
          },
          () => {
            navigate(-1);
          }
        );
      }
    };
    initTown();
  }, [getTown, id, navigate]);

  useEffect(() => {
    if (town) {
      formik.setFieldValue("name", town.name);
      formik.setFieldValue("title", town.title);
      formik.setFieldValue("description", town.description);
      formik.setFieldValue("active", town.active);
      getLinks({ townId: town.id }).then(
        ({ data }) => {
          if (data) {
            setLinks(data.list);
            initialLinksRef.current = data.list;
          }
          setLinksLoading(false);
        },
        () => {
          setLinks([]);
        }
      );
      getButtons({ townId: town.id }).then(async ({ data }) => {
        if (data) {
          const buttonsWithFiles: {
            id?: number;
            label: string;
            file: UploadFile[];
          }[] = await Promise.all(
            data.list.map(async (item) => {
              let files: UploadFile[] = [];

              const { data: fileUrl } = await getContentImage({
                path: item.fileUrl,
              });

              if (!fileUrl) {
                return { id: item.id, label: item.label, file: [] };
              }

              let fileObj: UploadFile | null = null;
              if (fileUrl) {
                const file = new File([fileUrl], item.fileUrl, {
                  type: fileUrl.type,
                });

                fileObj = {
                  originFileObj: file,
                  name: item.fileUrl.split("/").pop() || "",
                  uid: String(Date.now() + Math.random()), // Evitiamo duplicati
                  status: "done",
                  type: file.type || "file",
                  url: URL.createObjectURL(file),
                } as UploadFile;

                if (fileObj) {
                  files.push(fileObj);
                }
              }

              return {
                id: item.id,
                label: item.label,
                file: files || [],
              };
            })
          );

          setButtons(buttonsWithFiles);
          setButtonsLoading(false);
          initialButtonsRef.current = buttonsWithFiles;
        }
      });
    }
  }, [town, getLinks]);

  const handleFieldChange = useCallback(
    (key: string) => (value: string) => {
      formik.setFieldValue(key, value);
    },
    []
  );

  const handleActiveChange = useCallback((checked: boolean) => {
    formik.setFieldValue("active", checked);
  }, []);

  const handleAddLinkClick = useCallback(() => {
    setLinks((previous) => [...previous, { label: "", url: "" }]);
    setLinksEdited(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setButtons((previous) => [...previous, { label: "", file: [] }]);
    setButtonsEdited(true);
  }, []);

  const handleLinkFieldChange = useCallback(
    (key: string, index: number) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setLinks((prevLink) =>
          prevLink.map((link, i) =>
            i === index ? { ...link, [key]: e.target.value } : link
          )
        );
        setLinksEdited(true);
      },
    []
  );

  const handleRemoveLink = useCallback(
    (index: number) => () => {
      setLinks((prevLinks) => prevLinks.filter((_link, i) => i !== index));
      setLinksEdited(true);
    },
    []
  );

  const handleRemoveButton = useCallback(
    (index: number) => () => {
      setButtons((prevButtons) =>
        prevButtons.filter((_button, i) => i !== index)
      );
      setButtonsEdited(true);
    },
    []
  );

  const handleDownload = (file: UploadFile) => {
    const link = document.createElement("a");
    link.href = file.url || file.thumbUrl || "";
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleButtonFieldChange = useCallback(
    (key: string, index: number) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setButtons((prevButtons) =>
          prevButtons.map((button, i) =>
            i === index ? { ...button, [key]: e.target.value } : button
          )
        );
        setButtonsEdited(true);
      },
    []
  );

  return (
    <Container maxWidth={"lg"}>
      <PageToolbar
        onBack={() => navigate(-1)}
        title={`${id === "create" ? "Nuovo " : "Modifica "}Comune`}
      />
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "start",
        }}
      >
        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="name"
          placeholder="Nome"
          name="name"
          disabled={formik.isSubmitting}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          type="text"
          margin="normal"
          required
          fullWidth
          id="title"
          placeholder="Titolo"
          name="title"
          disabled={formik.isSubmitting}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <ReactQuill
          formats={formats}
          modules={modules}
          theme="snow"
          placeholder="Nome"
          id="name"
          value={formik.values.description}
          onChange={handleFieldChange("description")}
        />

        <Space direction="vertical">
          <Switch
            checkedChildren="Attivo"
            unCheckedChildren="Disattivo"
            id="active"
            checked={formik.values.active}
            onChange={handleActiveChange}
          />
        </Space>

        <Row>
          <Col span={11}>
            {linksLoading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  type="dashed"
                  className="mb-3"
                  onClick={handleAddLinkClick}
                  style={{ width: "60%" }}
                  icon={<AddIcon />}
                >
                  Aggiungi Link
                </Button>

                {links.length > 0 ? (
                  <>
                    {links.map((link, index) => (
                      <Row key={index} className="d-flex flex-row">
                        <Col span={11}>
                          <Input
                            value={link.label}
                            size="large"
                            placeholder="Etichetta"
                            onChange={handleLinkFieldChange("label", index)}
                          />
                        </Col>
                        <Col span={11}>
                          <Input
                            value={link.url}
                            size="large"
                            placeholder="URL"
                            onChange={handleLinkFieldChange("url", index)}
                          />
                        </Col>
                        <Col span={2}>
                          <IconButton onClick={handleRemoveLink(index)}>
                            <HighlightOffIcon />
                          </IconButton>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Alert className="p-2 mb-3">
                    <InfoIcon />
                    <span className="ms-2">Nessun Link Selezionato</span>
                  </Alert>
                )}
              </>
            )}
          </Col>
          <Col span={11}>
            {buttonsLoading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  type="dashed"
                  className="mb-3"
                  onClick={handleAddButtonClick}
                  style={{ width: "60%" }}
                  icon={<AddIcon />}
                >
                  Aggiungi File Scaricabili
                </Button>

                {buttons.length > 0 ? (
                  <>
                    {buttons.map((button, index) => (
                      <Row key={index} className="d-flex flex-row mb-4">
                        <Col span={11}>
                          <Input
                            value={button.label}
                            size="large"
                            placeholder="Etichetta"
                            onChange={handleButtonFieldChange("label", index)}
                          />
                        </Col>
                        <Col span={11}>
                          <Upload
                            fileList={buttons[index].file}
                            onChange={handleFileChange(index)}
                            {...fileProps}
                            listType="picture"
                          >
                            <Button icon={<UploadOutlined />}>
                              Carica File
                            </Button>
                          </Upload>
                        </Col>
                        <Col span={2}>
                          <IconButton onClick={handleRemoveButton(index)}>
                            <HighlightOffIcon />
                          </IconButton>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Alert className="p-2 mb-3">
                    <InfoIcon />
                    <span className="ms-2">
                      Nessun Pulsante di Download Selezionato
                    </span>
                  </Alert>
                )}
              </>
            )}
          </Col>
        </Row>

        <MaterialButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            formik.isSubmitting ||
            error ||
            !formik.dirty ||
            (Object.keys(formik.errors).length > 0 &&
              !linksEdited &&
              !buttonsEdited)
          }
          sx={{ mt: 3, mb: 2, backgroundColor: LightTheme.colors.primary }}
        >
          {formik.isSubmitting
            ? "Salvataggio..."
            : `${id === "create" ? "Salva " : "Modifica "}Comune`}
        </MaterialButton>
      </Box>
    </Container>
  );
}
