import React, { useEffect, useState } from "react";
import { useLazyGetSocialResponsibilitiesQuery } from "../../api/Certifications";
import { Certification } from "../../types/Certifications";
import "./ResponsabilitaSocialeView.css";
import { Row } from "react-bootstrap";
import { Col } from "antd";
import { useMediaQuery } from "@mui/material";
import { bpTheme } from "../../Providers/ThemeProvider";
import { useLazyGetWhistleblowingsQuery } from "../../api/Whistleblowing";
import { Whistleblowing } from "../../types/Whistleblowing";

export default function ResponsabilitaSocialeView() {
  const isSm = useMediaQuery(bpTheme.breakpoints.down("sm"));
  const [getSocialResponsibilities] = useLazyGetSocialResponsibilitiesQuery();
  const [getWhistleblowings] = useLazyGetWhistleblowingsQuery();
  const [whistleBlowing, setWhistleblowing] = useState<
    Whistleblowing | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [whistleblowingLoading, setWhistleblowingLoading] = useState(true);
  const [responsibility, setResponsibility] = useState<
    Certification | undefined
  >();

  useEffect(() => {
    getSocialResponsibilities({}).then(
      ({ data }) => {
        if (data && data.list.length > 0) {
          setResponsibility(data.list[0]);
          setLoading(false);
        } else {
          setResponsibility(undefined);
          setLoading(false);
        }
      },
      () => {
        setResponsibility(undefined);
        setLoading(false);
      }
    );
  }, [getSocialResponsibilities]);

  useEffect(() => {
    getWhistleblowings({}).then(
      ({ data }) => {
        if (data && data.list.length > 0) {
          setWhistleblowing(data.list[0]);
          setWhistleblowingLoading(false);
        } else {
          setWhistleblowing(undefined);
          setWhistleblowingLoading(false);
        }
      },
      () => {
        setWhistleblowing(undefined);
        setWhistleblowingLoading(false);
      }
    );
  }, [getWhistleblowings]);

  const handleDownload =
    (signedUrl: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const link = document.createElement("a");
      link.href = signedUrl;
      link.setAttribute("download", "file"); // Nome del file (opzionale, alcuni URL potrebbero non supportarlo)
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <div
      className="px-5"
      style={{
        position: "relative",
        paddingTop: "16vh",
        minHeight: "80vh",
        paddingBottom: "8vh",
        width: "100%",
        overflow: "auto",
        backgroundColor: "#408432",
        color: "#FFF",
      }}
    >
      <div className="background-text">
        <span>RESPONSABILITA' SOCIALE</span>
      </div>
      <div
        style={{
          minHeight: "100%",
          justifyContent: "center",
        }}
      >
        {!loading && !responsibility ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <p className="h4">
              Nessuna Responsabilità Sociale Presente in Archivio
            </p>
          </div>
        ) : (
          <div className="pb-5">
            <Row>
              <Col span={24} className="gap-3">
                <div className="d-flex flex-row gap-3 align-items-center mb-2">
                  <img
                    style={{ width: 100, height: 100 }}
                    src={responsibility?.signedImageUrl}
                    alt={responsibility?.title}
                  />
                  <p className="h2 text-uppercase">{responsibility?.title}</p>
                </div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: responsibility?.text || "",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSm ? "column" : "row",
                    marginBottom: 32,
                    gap: 32,
                  }}
                >
                  <span className="h6 text-uppercase">Files: &nbsp;</span>
                  {(responsibility?.signedFileUrls || []).map((file, index) => (
                    <span key={index}>
                      <a
                        onClick={handleDownload(file)}
                        target="_blank"
                        rel="noopener"
                        className="my-btn"
                      >
                        {`File ${index + 1}`}
                      </a>
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {!loading && !whistleBlowing ? (
          <div
            style={{
              textAlign: "center",
            }}
          ></div>
        ) : (
          <div>
            <Row>
              <Col span={24} className="gap-3">
                <div className="d-flex flex-row gap-3 align-items-center mb-2">
                  <img
                    style={{ width: 100, height: 100 }}
                    src={whistleBlowing?.signedImageUrl}
                    alt={whistleBlowing?.title}
                  />
                  <p className="h2 text-uppercase">{whistleBlowing?.title}</p>
                </div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: whistleBlowing?.text || "",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 32,
                  }}
                >
                  <span>
                    <a
                      href={whistleBlowing?.buttonLink}
                      target="_blank"
                      rel="noopener"
                      className="my-btn"
                    >
                      {whistleBlowing?.buttonLabel}
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}
