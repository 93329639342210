import { FC, ReactNode } from "react";
import { Divider, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LightTheme } from "../../Providers/ThemeProvider";

interface PropsPageToolbar {
  title: ReactNode;
  subtitle?: ReactNode;
  onBack?: () => void;
}

const PageToolbar: FC<PropsPageToolbar> = ({
  title,
  subtitle,
  onBack,
}: PropsPageToolbar): JSX.Element => {
  return (
    <>
      <Toolbar disableGutters style={{ marginBottom: ".5rem" }}>
        {onBack && (
          <IconButton
            color={"primary"}
            onClick={onBack}
            style={{
              width: "58px",
              height: "58px",
              marginRight: "1rem",
              color: LightTheme.colors.text,
            }}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <div>
          <Typography
            color={LightTheme.colors.text}
            style={{ fontWeight: 600, textTransform: "uppercase" }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="body2"
              style={{ fontSize: 16 }}
              color="textSecondary"
            >
              {subtitle}
            </Typography>
          )}
        </div>
      </Toolbar>
      <Divider style={{ marginBottom: "2rem" }} className="line" />
    </>
  );
};

export default PageToolbar;
