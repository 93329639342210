import {
  createContext,
  FC,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "../types/User";
import { redirect } from "react-router-dom";
import { Auth } from "../api/Auth";

type AuthContextType = {
  isReady: boolean;
  user: User | null;
  signIn: (
    username: string,
    password: string,
    callback: (success: { user: User } | null, failure: any) => void
  ) => void;
  signOut: () => void;
};

const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);

  const signIn = async (
    username: string,
    password: string,
    callback: (success: { user: User } | null, failure: any) => void
  ) => {
    Auth.authAPISignIn({ username, password }).then(
      ({ data }) => {
        setUser(data.user);
        callback({ user: data.user }, null);
        sessionStorage.setItem("User", JSON.stringify(data.user));
        sessionStorage.setItem(
          "Access-Token",
          JSON.stringify(data.accessToken)
        );
        sessionStorage.setItem(
          "Refresh-Token",
          JSON.stringify(data.refreshToken)
        );
      },
      (err) => {
        callback(null, {
          code: err.response?.data?.statusCode || 401,
          message: err.response?.data?.message || "Errore sconosciuto",
        });
      }
    );
  };

  const signOut = async () => {
    await Auth.authAPISignOut()
      .then(
        async () => {
          setUser(null);
          await sessionStorage.removeItem("User");
          await sessionStorage.removeItem("Access-Token");
          await sessionStorage.removeItem("Refresh-Token");
          redirect("");
        },
        (err) => {
          console.error(err);
        }
      )
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const sessionUser = sessionStorage.getItem("User");
        if (sessionUser) {
          const parsedUser = JSON.parse(sessionUser);
          setUser(parsedUser);
        } else {
          const user = await Auth.getCurrentUser();
          if (user && user.id) {
            setUser(user);
            sessionStorage.setItem("User", JSON.stringify(user)); // Memorizza di nuovo se necessario
          }
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setIsReady(true);
      }
    };

    initializeAuth();
  }, []);

  const value = {
    isReady,
    user,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

AuthProvider.displayName = "AuthProvider";
export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
