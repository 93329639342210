import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Concernings } from "../../api/Concernings";
import { AxiosError } from "axios";

const initialState: {
  message: string;
  variant: "error" | "default" | "success" | "warning";
} = {
  message: "",
  variant: "default",
};

export const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    resetError: () => initialState,
    setError: (state, action: PayloadAction<string>) => {
      state.variant = "error";
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      Concernings.endpoints.getConcernings.matchRejected,
      (state, action) => {
        state.variant = "error";
        if (action.payload instanceof AxiosError) {
          state.message =
            action.payload.response?.data.statusCode === 403 ||
            action.payload.response?.data.statusCode === 401
              ? "Non sei autorizzato o la Sessione è scaduta, effettuo il Logout..."
              : action.payload.response?.data.message;
        }
      }
    );
    builder.addMatcher(
      Concernings.endpoints.getConcerning.matchRejected,
      (state, action) => {
        state.variant = "error";
        if (action.payload instanceof AxiosError) {
          state.message =
            action.payload.response?.data.statusCode === 403 ||
            action.payload.response?.data.statusCode === 401
              ? "Non sei autorizzato o la Sessione è scaduta, effettuo il Logout..."
              : action.payload.response?.data.message;
        }
      }
    );
    builder.addMatcher(
      Concernings.endpoints.createConcerning.matchRejected,
      (state, action) => {
        state.variant = "error";
        if (action.payload instanceof AxiosError) {
          state.message =
            action.payload.response?.data.statusCode === 403 ||
            action.payload.response?.data.statusCode === 401
              ? "Non sei autorizzato o la Sessione è scaduta, effettuo il Logout..."
              : action.payload.response?.data.message;
        }
      }
    );
  },
});

export const { resetError, setError } = errorSlice.actions;

export default errorSlice.reducer;
