import { closeSnackbar, SnackbarKey, useSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorSelector } from "../redux/selectors/errorSelector";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { resetError } from "../redux/slices/errorSlice";

export const CustomSnackbarProvider: FC = () => {
  const error: {
    message: string;
    variant: "error" | "default" | "success" | "warning";
  } = useSelector(errorSelector);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error.message !== "" && error.variant !== "default") {
      enqueueSnackbar({
        message: error.message,
        variant: error.variant,
        action: (key: SnackbarKey) => {
          return (
            <IconButton
              style={{ color: "white" }}
              onClick={() => closeSnackbar(key)}
            >
              <HighlightOffIcon />
            </IconButton>
          );
        },
      });
    }
    dispatch(resetError());
  }, [error, enqueueSnackbar, dispatch]);

  return <></>;
};

CustomSnackbarProvider.displayName = "CustomSnackbarProvider";

export default CustomSnackbarProvider;
