import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Town } from "../../../types/Town";
import { useLazyGetTownByNameQuery } from "../../../api/Town";
import { Col, Row } from "antd";
import { bpTheme, useTheme } from "../../../Providers/ThemeProvider";
import { useMediaQuery } from "@mui/material";
import image3 from "../../../assets/img/town_placeholder.jpg";

export default function TownDetailView() {
  const { name } = useParams();
  const isSm = useMediaQuery(bpTheme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [getTown] = useLazyGetTownByNameQuery();
  const [town, setTown] = useState<Town | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!name) {
      navigate(-1);
    } else {
      getTown({ name }).then(
        ({ data }) => {
          if (data) {
            setTown(data.town);
            setLoading(false);
          } else {
            navigate(-1);
            setLoading(false);
          }
        },
        () => {
          navigate(-1);
          setLoading(false);
        }
      );
    }
  }, [name, getTown]);

  const handleDownload =
    (signedUrl: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const link = document.createElement("a");
      link.href = signedUrl;
      link.setAttribute("download", "file"); // Nome del file (opzionale, alcuni URL potrebbero non supportarlo)
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        backgroundColor: "#408432",
        color: "#FFF",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: `100%`,
        }}
      >
        <div
          style={{
            backgroundImage: `url(${image3})`,
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="image"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          paddingLeft: isSm ? 0 : 32,
          top: "20%",
          color: "#FFF",
          fontFamily: '"Roboto" sans-serif',
          display: "flex",
          flexDirection: "column",
          gap: 32,
          paddingBottom: 24,
        }}
      >
        {!loading && !town ? (
          <p className="h4 justify-content-center text-align-center">
            Errore nel Reperimento del Comune. Per favore, riprova
          </p>
        ) : (
          <>
            <div className="d-flex flex-column gap-2 justify-content-center text-align-center align-items-center">
              <p className="h2 text-uppercase">{town?.name}</p>
              <span
                className="text-center"
                dangerouslySetInnerHTML={{ __html: town?.description || "" }}
              />
            </div>
            <Row
              style={{
                justifyContent: isSm ? "center" : "space-around",
                gap: 16,
                textAlign: "center",
              }}
            >
              {!isSm ? (
                <>
                  <Col span={8}>
                    <p className="h4 text-uppercase">Link Utili:</p>
                    <div className="d-flex flex-column">
                      {(town?.links || []).map((link, index) => (
                        <span key={index}>
                          <a
                            href={`${link.url}`}
                            style={{
                              color: "#FFF",
                              textDecoration: "underline",
                              fontSize: 16,
                            }}
                            target="_blank"
                            rel="noopener"
                          >
                            {link.label}
                          </a>
                        </span>
                      ))}
                    </div>
                  </Col>
                  <Col span={8}>
                    <p className="h4 text-uppercase">Download:</p>
                    <div className="d-flex flex-column gap-2">
                      {(town?.buttons || []).map((button, index) => (
                        <button
                          onClick={handleDownload(button.signedUrl || "")}
                          style={{
                            alignSelf: "center",
                            maxWidth: "50%",
                            padding: "12px 20px",
                            backgroundColor: theme.colors.secondaryButton,
                            border: "none",
                            color: "#FFF",
                            fontSize: "16px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              theme.colors.secondaryButtonHover)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              theme.colors.secondaryButton)
                          }
                          onMouseDown={(e) =>
                            (e.currentTarget.style.transform = "scale(0.95)")
                          }
                          onMouseUp={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        >
                          {button.label}
                        </button>
                      ))}
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={24} style={{backgroundColor: theme.colors.primary}}>
                    <p className="h4 text-uppercase">Link Utili:</p>
                    <div className="d-flex flex-column">
                      {(town?.links || []).map((link, index) => (
                        <span key={index}>
                          <a
                            href={`${link.url}`}
                            style={{
                              color: "#FFF",
                              textDecoration: "underline",
                              fontSize: 16,
                            }}
                            target="_blank"
                            rel="noopener"
                          >
                            {link.label}
                          </a>
                        </span>
                      ))}
                    </div>
                  </Col>
                  <Col span={24} style={{backgroundColor: theme.colors.primary}}>
                    <p className="h4 text-uppercase">Download:</p>
                    <div className="d-flex flex-column gap-2">
                      {(town?.buttons || []).map((button, index) => (
                        <button
                          onClick={handleDownload(button.signedUrl || "")}
                          style={{
                            alignSelf: "center",
                            padding: "12px 20px",
                            backgroundColor: theme.colors.secondaryButton,
                            border: "none",
                            color: "#FFF",
                            fontSize: "16px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              theme.colors.secondaryButtonHover)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              theme.colors.secondaryButton)
                          }
                          onMouseDown={(e) =>
                            (e.currentTarget.style.transform = "scale(0.95)")
                          }
                          onMouseUp={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        >
                          {button.label}
                        </button>
                      ))}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
}
