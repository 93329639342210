import { LoginBody, LoginResponse, User } from "../types/User";
import { getEndpoint } from "../utils/stringUtil";
import userClient from "./axios";

export const Auth = {
  authAPISignIn: (body: LoginBody) =>
    userClient.post<LoginResponse>(`${getEndpoint()}api/user/login`, body),
  authAPISignOut: () => userClient.post(`${getEndpoint()}api/user/logout`, {}),
  getCurrentUser: async () => {
    try {
      let userAuth: User | null = null;
      const user = sessionStorage.getItem("User");
      if (!!user) {
        let sessionUser = await JSON.parse(user);
        if (sessionUser) userAuth = sessionUser as User;
      }
      return userAuth;
    } catch (error) {
      if (error) return null;
    }
  },
};
