import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { Link, Outlet } from "react-router-dom";
import { IMenuItem } from "../types/IMenuItem";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InfoIcon from "@mui/icons-material/Info";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ReportIcon from "@mui/icons-material/Report";
import "./partials/AdminRoot.css";
import UIMenuItem from "./partials/UIMenuItem";
import { LightTheme } from "../Providers/ThemeProvider";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../Providers/AuthProvider";

export const menuList: Array<IMenuItem> = [
  {
    Icon: <WorkspacePremiumIcon />,
    label: "Certificazioni",
    to: "/certifications",
  },
  {
    Icon: <InfoIcon />,
    label: "Sezione Cosa Facciamo",
    to: "/about-us",
  },
  {
    Icon: <AccessibilityIcon />,
    label: "Sezione Responsabilità Sociale",
    to: "/social-responsibility",
  },
  {
    Icon: <LocationCityIcon />,
    label: "Sezione Comuni",
    to: "/towns",
  },
  {
    Icon: <ReportIcon />,
    label: "Sezione Whistleblowing",
    to: "/whistleblowing",
  },
];

const drawerWidth = 240;

export default function AdminRoot() {
  const { signOut } = useAuth();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: LightTheme.colors.secondary,
          border: `2px solid ${LightTheme.colors.primary}`,
          borderRight: "none",
          borderLeft: "none",
        }}
      >
        <Toolbar>
          <img
            src={require("../assets/img/logo.png")}
            style={{ width: 75, height: 50 }}
            alt="Logo"
          />
          <div style={{ flexGrow: 1 }}></div>
          <div>
            <IconButton onClick={signOut} aria-label="Logout" title="Logout">
              <LogoutIcon style={{ color: LightTheme.colors.primary }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: `2px solid ${LightTheme.colors.primary}`,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {menuList.map((item, index) => (
            <Link to={`/admin${item.to}`} className="navigate-link" key={index} title={item.label}>
              <UIMenuItem label={item.label} Icon={item.Icon} />
            </Link>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
