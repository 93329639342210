import { useEffect, useState } from "react";
import { useLazyGetCertificationsQuery } from "../../api/Certifications";
import { Certification } from "../../types/Certifications";
import "./CertificazioniView.css";
import { Row } from "react-bootstrap";
import { Col } from "antd";
import { useMediaQuery } from "@mui/material";
import { bpTheme } from "../../Providers/ThemeProvider";

export default function CeritficazioniView() {
  const isSm = useMediaQuery(bpTheme.breakpoints.down("sm"));
  const [getCertifications] = useLazyGetCertificationsQuery();
  const [loading, setLoading] = useState(true);
  const [certifications, setCertifications] = useState<Certification[]>([]);

  useEffect(() => {
    getCertifications({}).then(
      ({ data }) => {
        if (data) {
          setCertifications(data.list);
          setLoading(false);
        }
      },
      () => {
        setCertifications([]);
        setLoading(false);
      }
    );
  }, [getCertifications]);

  const handleDownload =
    (signedUrl: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const link = document.createElement("a");
      link.href = signedUrl;
      link.setAttribute("download", "file"); // Nome del file (opzionale, alcuni URL potrebbero non supportarlo)
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <div
      className="px-5"
      style={{
        position: "relative",
        paddingTop: "16vh",
        paddingBottom: "8vh",
        minHeight: "60vh",
        width: "100%",
        overflow: "auto",
        backgroundColor: "#408432",
        color: "#FFF",
      }}
    >
      <div className="background-text">
        <span>CERTIFICAZIONI</span>
      </div>
      <div
        className="pt-5"
        style={{
          minHeight: "100%",
          justifyContent: "center",
        }}
      >
        {!loading && certifications.length === 0 ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <p className="h4">Nessuna Certificazione Presente in Archivio</p>
          </div>
        ) : (
          <div>
            {certifications.map((el, index) => (
              <Row key={index}>
                {!isSm ? (
                  <>
                    <Col span={12} className="gap-3">
                      {index % 2 === 0 ? (
                        <div>
                          <div className="d-flex flex-row gap-2 align-items-center">
                            <img
                              style={{ width: 100, height: 100 }}
                              src={el.signedImageUrl}
                              alt={el.title}
                            />
                            <p className="h2 text-uppercase">{el.title}</p>
                          </div>
                          <span dangerouslySetInnerHTML={{ __html: el.text }} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isSm ? "column" : "row",
                              gap: 32,
                              marginTop: 24,
                            }}
                          >
                            <span>Files: &nbsp;</span>
                            {(el?.signedFileUrls || []).map((file, index) => (
                              <span key={index}>
                                <a
                                  onClick={handleDownload(file)}
                                  target="_blank"
                                  rel="noopener"
                                  className="my-btn"
                                >
                                  {`File ${index + 1}`}
                                </a>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }} className="gap-3">
                      {index % 2 === 1 ? (
                        <div style={{ textAlign: "end" }} className="mb-2">
                          <div
                            className="d-flex flex-row gap-2 align-items-center"
                            style={{ justifyContent: "end" }}
                          >
                            <p className="h2 text-uppercase">{el.title}</p>
                            <img
                              style={{
                                width: 100,
                                height: 100,
                                alignSelf: "flex-end",
                              }}
                              src={el.signedImageUrl}
                              alt={el.title}
                            />
                          </div>
                          <span dangerouslySetInnerHTML={{ __html: el.text }} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isSm ? "column" : "row",
                              gap: 32,
                              marginTop: 24,
                            }}
                          >
                            <span>Files: &nbsp;</span>
                            {(el?.signedFileUrls || []).map((file, index) => (
                              <span key={index}>
                                <a
                                  onClick={handleDownload(file)}
                                  target="_blank"
                                  rel="noopener"
                                  className="my-btn"
                                >
                                  {`File ${index + 1}`}
                                </a>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </>
                ) : (
                  <Col span={24} className="gap-3">
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <img
                        style={{ width: 100, height: 100 }}
                        src={el.signedImageUrl}
                        alt={el.title}
                      />
                      <p className="h2 text-uppercase">{el.title}</p>
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: el.text }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isSm ? "column" : "row",
                        gap: 32,
                        marginTop: 24,
                      }}
                    >
                      <span>Files: &nbsp;</span>
                      {(el?.signedFileUrls || []).map((file, index) => (
                        <span key={index}>
                          <a
                            onClick={handleDownload(file)}
                            target="_blank"
                            rel="noopener"
                            className="my-btn"
                          >
                            {`File ${index + 1}`}
                          </a>
                        </span>
                      ))}
                    </div>
                  </Col>
                )}
              </Row>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
